import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import Login from "./pages/Login";
import Main from "./pages/Main";
import "devextreme/dist/css/dx.light.compact.css";
import Survey from "./pages/Survey";
import { RequireAuth } from "react-auth-kit";
import SurveyEdit from "./pages/SurveyEdit";
import Result from "./pages/Result";

function App() {
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/main"
                element={
                  <RequireAuth loginPath="/">
                    <Main />
                  </RequireAuth>
                }
              />
              <Route
                path="/survey"
                element={
                  <RequireAuth loginPath="/">
                    <Survey />
                  </RequireAuth>
                }
              />
              <Route
                path="/survey/:id"
                element={
                  <RequireAuth loginPath="/">
                    <SurveyEdit />
                  </RequireAuth>
                }
              />
              <Route
                path="/survey/result/:id"
                element={
                  <RequireAuth loginPath="/">
                    <Result />
                  </RequireAuth>
                }
              />
            </Routes>
          </BrowserRouter>
        </RecoilRoot>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
