import styled from "styled-components";

export const MainPage = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: auto;
  align-items: center;
  flex-direction: column;
`;

export const MainContent = styled.section`
  display: flex;
  width: 100vw;
  height: calc(100vh - 50px);
  padding: 16px;
  flex-direction: column;
  gap: 16px;
`;
