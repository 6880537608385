import styled from "styled-components";

export const SurveySection = styled.div`
  weight: 100%;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.light};
`;

export const SurveyTit = styled.div`
  grid-template-columns: 1fr;
  color: #484848;
  font-size: 3rem;
  font-weight: 500;
  font-family: "Roboto";
  text-align: center;
`;

export const SurveySubTit = styled.div`
  grid-area: TT;
  color: #484848;
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 20px;
`;

export const SurveyFormBox = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin: auto 20px;
`;

export const SurveyItems = styled.div`
  display: grid;
  grid-template-areas:
    "TT"
    "IT";
`;

export const ItemWrapper = styled.div`
  grid-area: IT;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;
`;

export const SurveySubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
