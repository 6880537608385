import { SurveyEditForm } from "../../molecules/SurveyEditForm";
import * as S from "./surveyEditSection.style";

const SurveyEditSection = () => {
  return (
    <S.SurveySection>
      <SurveyEditForm />
    </S.SurveySection>
  );
};

export default SurveyEditSection;
