import React from "react";
import * as S from "./resultGrid.style";
import { SurveyResultType } from "../../../pages/Result";

interface ResultGridProps {
  surveyResult: SurveyResultType;
}
const ResultGrid = ({ surveyResult }: ResultGridProps) => {
  return (
    <S.ResultGridBox>
      <S.ResultItems>
        <S.ResultGridTit>체력 측정</S.ResultGridTit>
        <div>
          <S.ResultGridSub>
            오른손 악력(평균): {surveyResult?.R_gripstrength_average} %
          </S.ResultGridSub>
          <S.ResultGridSub>
            왼손 악력(평균): {surveyResult?.L_gripstrength_average} %
          </S.ResultGridSub>
          <S.ResultGridSub>
            하지근력(앉았다 일어서기) : {surveyResult?.pat_sit_n_up} (
            {surveyResult?.pat_sit_n_up_eval})
          </S.ResultGridSub>
          {surveyResult?.pat_age > 64 ? (
            <S.ResultGridSub>
              협응력(8자 보행) : {surveyResult?.pat_eightwalk} (
              {surveyResult?.pat_eightwalk_eval})
            </S.ResultGridSub>
          ) : (
            <S.ResultGridSub>
              제자리 높이 뛰기 : {surveyResult?.pat_standing_highjump} (
              {surveyResult?.pat_standing_highjump_eval})
            </S.ResultGridSub>
          )}
        </div>
      </S.ResultItems>
      <S.ResultItems>
        <S.ResultGridTit>GateView</S.ResultGridTit>
        <S.ImgPreviewItem>
          <img
            src={
              surveyResult?.filename &&
              `${process.env.REACT_APP_PUBLIC_API_URL}/${surveyResult?.filename}`
            }
            alt={surveyResult?.filename}
            width={400}
            height={400}
          />
        </S.ImgPreviewItem>
        <div>
          <S.ResultGridSub>ENV : {surveyResult?.pat_env}</S.ResultGridSub>
          <S.ResultGridSub>REC : {surveyResult?.pat_rec}</S.ResultGridSub>
          <S.ResultGridSub>RMS : {surveyResult?.pat_rms}</S.ResultGridSub>
          <S.ResultGridSub>TLC : {surveyResult?.pat_tlc}</S.ResultGridSub>
          <S.ResultGridSub>
            Total Length : {surveyResult?.pat_total_length}
          </S.ResultGridSub>
          <S.ResultGridSub>
            Sway velccity : {surveyResult?.pat_sway_velccity}
          </S.ResultGridSub>
          <S.ResultGridSub>
            Length/ENV : {surveyResult?.pat_length_env}
          </S.ResultGridSub>
        </div>
      </S.ResultItems>
      <S.ResultItems>
        <S.ResultGridTit>보행 패턴</S.ResultGridTit>
        <div>
          <S.ResultGridSub>
            목신전·굴곡-앞 (deg) : {surveyResult?.pat_neck_flexion_front}
          </S.ResultGridSub>
          <S.ResultGridSub>
            목신전·굴곡-뒤 (deg) : {surveyResult?.pat_neck_flexion_back}
          </S.ResultGridSub>
          <S.ResultGridSub>
            몸통 전후기울기-앞 (deg) : {surveyResult?.pat_bodybf_incline_front}
          </S.ResultGridSub>
          <S.ResultGridSub>
            몸통 전후기울기-뒤 (deg) : {surveyResult?.pat_bodybf_incline_back}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 신전·굴곡-오른쪽 앞 (deg) :
            {surveyResult?.pat_hipjoint_flexion_r_front}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 신전·굴곡-오른쪽 뒤 (deg) :
            {surveyResult?.pat_hipjoint_flexion_r_back}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 신전·굴곡-왼쪽 앞 (deg) :
            {surveyResult?.pat_hipjoint_flexion_l_front}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 신전·굴곡-왼쪽 뒤 (deg) :
            {surveyResult?.pat_hipjoint_flexion_l_back}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 회전 각도-오른쪽 내 (deg) :
            {surveyResult?.pat_hipjoint_rotation_angle_r_in}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 회전 각도-오른쪽 외(deg) :
            {surveyResult?.pat_hipjoint_rotation_angle_r_out}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 회전 각도-왼쪽 내(deg) :
            {surveyResult?.pat_hipjoint_rotation_angle_l_in}
          </S.ResultGridSub>
          <S.ResultGridSub>
            고관절 회전 각도-왼쪽 외(deg) :
            {surveyResult?.pat_hipjoint_rotation_angle_l_out}
          </S.ResultGridSub>
          <S.ResultGridSub>
            무릎관절 신전·굴곡-좌 (deg) :{surveyResult?.pat_kneejoint_flexion_l}
          </S.ResultGridSub>
          <S.ResultGridSub>
            무릎관절 신전·굴곡-우 (deg) :{surveyResult?.pat_kneejoint_flexion_r}
          </S.ResultGridSub>
          <S.ResultGridSub>
            몸통 좌우 기울기-좌 (deg) : {surveyResult?.pat_bodylr_incline_l}
          </S.ResultGridSub>
          <S.ResultGridSub>
            몸통 좌우 기울기-우 (deg) : {surveyResult?.pat_bodylr_incline_r}
          </S.ResultGridSub>
          <S.ResultGridSub>
            골반 좌우 이동 변위-좌 (cm) :{surveyResult?.pat_pelvis_lr_movment_l}
          </S.ResultGridSub>
          <S.ResultGridSub>
            골반 좌우 이동 변위-우 (cm) :{surveyResult?.pat_pelvis_lr_movment_r}
          </S.ResultGridSub>
          <S.ResultGridSub>
            수직 이동 변위-위 (cm) : {surveyResult?.pat_vertical_movement_up}
          </S.ResultGridSub>
          <S.ResultGridSub>
            골반 회전 각도-좌 (deg) :{surveyResult?.pat_pelvic_rotation_angle_l}
          </S.ResultGridSub>
          <S.ResultGridSub>
            골반 회전 각도-우 (deg) :{surveyResult?.pat_pelvic_rotation_angle_r}
          </S.ResultGridSub>
          <S.ResultGridSub>
            보간(cm) : {surveyResult?.pat_step_length}
          </S.ResultGridSub>
          <S.ResultGridSub>
            보폭-좌 (cm) : {surveyResult?.pat_stride_l}
          </S.ResultGridSub>
          <S.ResultGridSub>
            보폭-우 (cm) : {surveyResult?.pat_stride_r}
          </S.ResultGridSub>
        </div>
      </S.ResultItems>
    </S.ResultGridBox>
  );
};

export default ResultGrid;
