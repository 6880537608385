import { useNavigate } from "react-router";
import { Button } from "../../atoms/Button";
import * as S from "./surveyHeader.style";

interface SurveyHeaderProps {
  title?: string;
}
export const SurveyHeader = ({ title }: SurveyHeaderProps) => {
  const navigate = useNavigate();

  return (
    <S.SurveyHeader>
      <S.SurveyTit>{title}</S.SurveyTit>
      <Button
        type="button"
        color="func"
        layout="solid"
        width="80px"
        size="md"
        label="홈으로"
        onClick={() => navigate("/main")}
      />
    </S.SurveyHeader>
  );
};
