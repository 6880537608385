import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 16px;
  background: ${(props) => props.theme.colors.searchBarBg};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  gap: 20px;
`;
