import { InputCheckbox } from "../../atoms/Input/InputCheckbox";
import * as S from "./surveyAgree.style";

interface SurveyAgree {
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SurveyAgree = ({ setDisable }: SurveyAgree) => {
  return (
    <S.SurveyAgreeBox>
      <S.SurveyAgreeTit>실험 참여 및 개인정보 제공 동의서</S.SurveyAgreeTit>
      <S.SurveyAgreeTxt>
        <p>
          이 연구 실험에서는 대상자에게 건강문제 및 건강위험요인에 따른 중재 및
          건강한 생활을 실천할 수 있는 동기를 부여하고, 개인의 신체측정 및 실험
          참여를 위하여 진행되고 있습니다.
        </p>
        <p>
          실험에 참여하기 위해서는 서비스 제공 절차와 원칙을 준수해야 하며,
          개인의 정보 활용에 동의 하여야 합니다.
        </p>
        <p>
          이 실험을 통하여 수집된 귀하의 정보는 과학적 연구자료로만 사용되며
          다른 목적으로는 절대 사용되지 않을 것입니다.
        </p>
      </S.SurveyAgreeTxt>
      <InputCheckbox
        themeType="admin"
        layout="row"
        displayValue="정보 제공에 동의합니다."
        onChange={() => setDisable((prev: any) => !prev)}
      />
    </S.SurveyAgreeBox>
  );
};
