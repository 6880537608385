import * as yup from "yup";

export const schema = yup
  .object({
    pat_id: yup.string().nullable().required("아이디를 입력해주세요"),
    pat_password: yup.string().nullable().required("비밀번호를 입력해주세요"),
    pat_foot_size: yup.number().nullable().required("발사이즈를 입력해주세요"),
    pat_name: yup.string().nullable().required("이름을 입력해주세요"),
    pat_phone: yup.string().required("전화번호를 입력해주세요"),
    pat_birthdate: yup.date().required("생년월일을 선택하세요"),
    pat_sex: yup.number().required("성별을 선택해주세요"),
    pat_bodylength: yup.number().required("신장을 입력해주세요"),
    pat_weight: yup.number().required("체중을 입력해주세요"),

    // pat_bmi: yup.number(),
    pat_bodyfat_percentage: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_amount_musle: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_bone_musle: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),

    pat_left_gripstrength_01: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_left_gripstrength_02: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_left_gripstrength_03: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_right_gripstrength_01: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_right_gripstrength_02: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_right_gripstrength_03: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_sit_n_up: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_eightwalk: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_standing_highjump: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),

    pat_neck_flexion_back: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_neck_flexion_front: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_bodybf_incline_back: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_bodybf_incline_front: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_flexion_r_front: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_flexion_r_back: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_flexion_l_front: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_flexion_l_back: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_rotation_angle_r_in: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_rotation_angle_r_out: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_rotation_angle_l_in: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_hipjoint_rotation_angle_l_out: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_kneejoint_flexion_l: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_kneejoint_flexion_r: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_bodylr_incline_r: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_bodylr_incline_l: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_pelvis_lr_movment_r: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_pelvis_lr_movment_l: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_vertical_movement_up: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_pelvic_rotation_angle_r: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_pelvic_rotation_angle_l: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_step_length: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_stride_r: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_stride_l: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),

    pat_outer_area: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_single_area: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_effective_area: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_centerpoint_distance: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_trajectory_field: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_cop_speed: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_env: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_rec: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_rms: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_tlc: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_total_length: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_sway_velccity: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
    pat_length_env: yup
      .number()
      .typeError("숫자만 입력 가능합니다")
      .transform((_, val) => (val ? Number(val) : null))
      .nullable(),
  })
  .required();
