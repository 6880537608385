import React, { useEffect, useState } from "react";
import * as S from "./adminLoginBox.style";
import { Button } from "../../atoms/Button";
import { InputText } from "../../atoms/Input/InputText";

interface AdminLoginBoxProps {
  onSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  setUserInfo: React.Dispatch<
    React.SetStateAction<{ user_id: string; password: string }>
  >;
  userInfo: { user_id: string; password: string };
  isLoading: boolean;
  errorMessage: string;
}

export const AdminLoginBox = ({
  onSubmitForm,
  setUserInfo,
  userInfo,
  isLoading,
  errorMessage,
}: AdminLoginBoxProps) => {
  return (
    <S.AdminLoginBox onSubmit={onSubmitForm}>
      <S.LoginTit>로그인</S.LoginTit>
      <S.LoginInputBox>
        <InputText
          label="아이디"
          layout="column"
          themeType="admin"
          size="xlg"
          width="100%"
          type={"text"}
          placeholder="아이디 입력"
          value={userInfo.user_id}
          onChange={({ target }: any) =>
            setUserInfo({ ...userInfo, user_id: target.value })
          }
        />
        <InputText
          label="비밀번호"
          layout="column"
          themeType="admin"
          size="xlg"
          width="100%"
          type={"password"}
          placeholder="비밀번호 입력"
          value={userInfo.password}
          onChange={({ target }: any) =>
            setUserInfo({ ...userInfo, password: target.value })
          }
        />
        {errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
      </S.LoginInputBox>

      <Button
        type="submit"
        size="xlg"
        width="100%"
        color="primary"
        layout="solid"
        label="로그인"
        className={`${isLoading && "spinner spinner-white spinner-right"}`}
      />
    </S.AdminLoginBox>
  );
};
