import { useEffect, useLayoutEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../atoms/Button";
import { InputDate } from "../../atoms/Input/InputDate";
import { InputRadio } from "../../atoms/Input/InputRadio";
import { InputText } from "../../atoms/Input/InputText";
import {
  deleteImageApi,
  deletePreviewImagesApi,
  editSurveyApi,
  getOneSurveyApi,
  uploadImagesApi,
} from "../../../apis/surveyApi";
import * as S from "./surveyEditForm.style";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputFile } from "../../atoms/Input/InputFile";
import {
  eightWalkRelativeEvaluation,
  gripstrengthRelativeEvaluationL,
  gripstrengthRelativeEvaluationR,
  sitNupRelativeEvaluation,
  standingHighjumpEvaluation,
} from "../../../lib/relativeEvaluation";
import { schema } from "./schema";

interface BmiState {
  pat_bodylength: number | null;
  pat_weight: number | null;
  pat_bmi: number | null;
}

export const SurveyEditForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const queryFn = () => {
    return getOneSurveyApi(params.id!);
  };

  const { data: dataSource } = useQuery(["getOneSurveyApi"], queryFn);

  const [img, setImg] = useState(dataSource?.filename);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [imgPreview, setImgPreview] = useState();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [bmiInfo, setBmiInfo] = useState<BmiState>({
    pat_bodylength: dataSource?.pat_bodylength,
    pat_weight: dataSource?.pat_weight,
    pat_bmi: dataSource?.pat_bmi,
  });

  /** 수정 저장 api */
  const mutation = useMutation(["editSurveyApi"], editSurveyApi, {
    onSuccess() {
      navigate("/main");
    },
  });

  /** 수정 onclick 핸들러 */
  const onSubmit: SubmitHandler<any> = (data) => {
    const today = new Date();
    const birthDate = new Date(data.pat_birthdate); // 2000년 8월 10일

    // 만나이 계산식
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    data.pat_age = age;
    // 제출 시 전화번호 하이픈 제거
    data.pat_phone = data.pat_phone.replace(/[^0-9]/g, "");

    // 8자보행 상대평가 기준 산출

    const eightWalk = eightWalkRelativeEvaluation(
      data.pat_sex,
      data.pat_age,
      data.pat_eightwalk
    );

    // 앉았다 일어서기 상대평가 기준 산출
    const sitNup = sitNupRelativeEvaluation(
      data.pat_sex,
      data.pat_age,
      data.pat_sit_n_up
    );

    // 왼쪽 상대 악력
    const LgripAverage =
      ((data.pat_left_gripstrength_01 +
        data.pat_left_gripstrength_02 +
        data.pat_left_gripstrength_03) /
        3 /
        data.pat_weight) *
      100;

    const LgripAverageResult = LgripAverage.toFixed(2);
    const Lgripstrength = gripstrengthRelativeEvaluationL(
      data.pat_sex,
      data.pat_age,
      Number(LgripAverageResult)
    );

    // 오른쪽 상대 악력
    const RgripAverage =
      ((data.pat_right_gripstrength_01 +
        data.pat_right_gripstrength_02 +
        data.pat_right_gripstrength_03) /
        3 /
        data.pat_weight) *
      100;

    const RgripAverageResult = RgripAverage.toFixed(2);
    const Rgripstrength = gripstrengthRelativeEvaluationR(
      data.pat_sex,
      data.pat_age,
      Number(RgripAverageResult)
    );

    /** 제자리 높이 뛰기 상대평가 등급 */
    if (age < 65) {
      const standingHighjump = standingHighjumpEvaluation(
        data.pat_sex,
        data.pat_age,
        data.pat_standing_highjump
      );
      data.pat_standing_highjump_eval = standingHighjump;
    } else {
      data.pat_standing_highjump_eval = null;
    }

    // 좌/우 악력 상대평가기준 점수 추가
    data.pat_left_gripstrength_eval = Lgripstrength;
    data.pat_right_gripstrength_eval = Rgripstrength;

    // 앉았다 일어서기 & 8자보행 상대평가기준 점수 추가
    data.pat_sit_n_up_eval = sitNup;
    data.pat_eightwalk_eval = eightWalk;

    // react-hook-form에 의해 안들어가는 bmi 값 수정 필요
    data.pat_bmi = bmiInfo.pat_bmi;

    const surveyData = {
      id: params.id!,
      data: {
        data: data,
        files: imgPreview,
      },
    };

    mutation.mutate(surveyData);
  };

  /** 서버 이미지 삭제 */
  const onRemoveImage = (v: any, e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    deleteImageApi(v).then(() => {
      setImg(undefined);
    });
  };

  /** 새로운 이미지 추가 된후 삭제버튼 preview 이미지 삭제 */
  const onRemovePreviewImage = (
    v: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    deletePreviewImagesApi(v.filename).then(() => {
      setImgPreview(undefined);
    });
  };

  const phoneNumberReg = (number: string) => {
    setPhoneNumber(
      number
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/(\-{1,2})$/g, "")
    );
  };

  const onChangeImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const imageFormData = new FormData();
    [].forEach.call(e.target.files, (f: any) => {
      imageFormData.append("files", f);
    });
    uploadImagesApi(imageFormData).then((result) => {
      setImgPreview(result);
    });
  };

  // 불러온 설문값 hookform default value로  reset
  useLayoutEffect(() => {
    setBmiInfo({
      pat_bodylength: dataSource?.pat_bodylength,
      pat_weight: dataSource?.pat_weight,
      pat_bmi: dataSource?.pat_bmi,
    });
    setPhoneNumber(
      dataSource?.pat_phone
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/(\-{1,2})$/g, "")
    );
    reset(dataSource);
    setImg(dataSource?.filename);
  }, [dataSource]);

  useEffect(() => {
    // bmi값을 구하기 위한 키와 몸무게 값이 다들어오면 bmi 계산
    if (bmiInfo.pat_bodylength && bmiInfo.pat_weight) {
      //bmi계산식 toFixed( bmi 소수점 두자리 수까지만 남김 )
      const bmi = (
        bmiInfo.pat_weight /
        ((bmiInfo.pat_bodylength / 100) * (bmiInfo.pat_bodylength / 100))
      ).toFixed(2);
      setBmiInfo({
        ...bmiInfo,
        pat_bmi: Number(bmi),
      });
    } else {
      setBmiInfo({
        ...bmiInfo,
      });
    }
  }, [bmiInfo.pat_bodylength, bmiInfo.pat_weight]);

  return (
    <S.SurveyEditForm onSubmit={handleSubmit(onSubmit)}>
      <S.SurveyItems>
        <S.SurveySubTit>기본 정보</S.SurveySubTit>
        <S.ItemWrapper>
          <InputText
            label="아이디"
            layout="column"
            type="text"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_id")}
            error={errors}
            name="pat_id"
          />
          <InputText
            label="비밀번호"
            layout="column"
            type="password"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_password")}
            error={errors}
            name="pat_password"
          />
          <InputText
            label="발사이즈"
            layout="column"
            type="number"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_foot_size")}
            error={errors}
            name="pat_foot_size"
          />
          <InputText
            label="이름"
            layout="column"
            type="text"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_name")}
            error={errors}
            name="pat_name"
          />
          <InputText
            label="전화번호"
            layout="column"
            themeType="admin"
            type="text"
            size="xlg"
            width="100%"
            placeholder="입력..."
            value={phoneNumber}
            onChange={({ target }: any) => phoneNumberReg(target.value)}
            register={register("pat_phone")}
            error={errors}
            name="pat_phone"
          />
          <InputDate
            label="생년월일"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_birthdate")}
            error={errors}
            name="pat_birthdate"
          />
          <InputRadio
            title="성별"
            themeType="admin"
            layout="column"
            size="xlg"
            register={register("pat_sex")}
            error={errors}
            name="pat_sex"
          />
        </S.ItemWrapper>
      </S.SurveyItems>

      <S.SurveyItems>
        <S.SurveySubTit>신체 조성</S.SurveySubTit>
        <S.ItemWrapper>
          <InputText
            label="신장(cm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            value={bmiInfo.pat_bodylength}
            register={register("pat_bodylength")}
            onChange={({ target }: any) =>
              setBmiInfo({ ...bmiInfo, pat_bodylength: target.value })
            }
            error={errors}
            name="pat_bodylength"
          />
          <InputText
            label="체중(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            value={bmiInfo.pat_weight}
            register={register("pat_weight")}
            onChange={({ target }: any) =>
              setBmiInfo({ ...bmiInfo, pat_weight: target.value })
            }
            error={errors}
            name="pat_weight"
          />
          <InputText
            label="BMI"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            value={bmiInfo.pat_bmi && bmiInfo.pat_bmi}
            register={register("pat_bmi")}
            disable={true}
          />
          <InputText
            label="체지방률(%Fay)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_bodyfat_percentage")}
            error={errors}
            name="pat_bodyfat_percentage"
          />
          <InputText
            label="근육량(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_amount_musle")}
            error={errors}
            name="pat_amount_musle"
          />
          <InputText
            label="골격근(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_bone_musle")}
            error={errors}
            name="pat_bone_musle"
          />
        </S.ItemWrapper>
      </S.SurveyItems>

      <S.SurveyItems>
        <S.SurveySubTit>건강 체력</S.SurveySubTit>
        <S.ItemWrapper>
          <InputText
            label="악력-왼쪽 1차(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_left_gripstrength_01")}
            error={errors}
            name="pat_left_gripstrength_01"
          />
          <InputText
            label="악력-오른쪽 1차(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_right_gripstrength_01")}
            error={errors}
            name="pat_right_gripstrength_01"
          />
          <InputText
            label="악력-왼쪽 2차(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_left_gripstrength_02")}
            error={errors}
            name="pat_left_gripstrength_02"
          />
          <InputText
            label="악력-오른쪽 2차(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_right_gripstrength_02")}
            error={errors}
            name="pat_right_gripstrength_02"
          />
          <InputText
            label="악력-왼쪽 3차(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_left_gripstrength_03")}
            error={errors}
            name="pat_left_gripstrength_03"
          />
          <InputText
            label="악력-오른쪽 3차(kg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_right_gripstrength_03")}
            error={errors}
            name="pat_right_gripstrength_03"
          />
          <InputText
            label="앉았다 일어서기(회)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_sit_n_up")}
            error={errors}
            name="pat_sit_n_up"
          />
          <InputText
            label="8자 보행(초)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_eightwalk")}
            error={errors}
            name="pat_eightwalk"
          />
          <InputText
            label="제자리 높이 뛰기(체공시간/초)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_standing_highjump")}
            error={errors}
            name="pat_standing_highjump"
          />
        </S.ItemWrapper>
      </S.SurveyItems>

      <S.SurveyItems>
        <S.SurveySubTit>보행 패턴</S.SurveySubTit>
        <S.ItemWrapper>
          <S.SurveyPatternTit>목 신전·굴곡</S.SurveyPatternTit>
          <InputText
            label="앞 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_neck_flexion_front")}
            error={errors}
            name="pat_neck_flexion_front"
          />
          <InputText
            label="뒤 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_neck_flexion_back")}
            error={errors}
            name="pat_neck_flexion_back"
          />

          <S.SurveyPatternTit>몸통 전후 기울기</S.SurveyPatternTit>
          <InputText
            label="앞 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_bodybf_incline_front")}
            error={errors}
            name="pat_bodybf_incline_front"
          />
          <InputText
            label="뒤 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_bodybf_incline_back")}
            error={errors}
            name="pat_bodybf_incline_back"
          />

          <S.SurveyPatternTit>고관절 신전·굴곡</S.SurveyPatternTit>
          <InputText
            label="오른쪽 앞 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_flexion_r_front")}
            error={errors}
            name="pat_hipjoint_flexion_r_front"
          />
          <InputText
            label="오른쪽 뒤 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_flexion_r_back")}
            error={errors}
            name="pat_hipjoint_flexion_r_back"
          />
          <InputText
            label="왼쪽 앞 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_flexion_l_front")}
            error={errors}
            name="pat_hipjoint_flexion_l_front"
          />
          <InputText
            label="왼쪽 뒤 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_flexion_l_back")}
            error={errors}
            name="pat_hipjoint_flexion_l_back"
          />

          <S.SurveyPatternTit>고관절 회전 각도</S.SurveyPatternTit>
          <InputText
            label="오른쪽 내 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_rotation_angle_r_in")}
            error={errors}
            name="pat_hipjoint_rotation_angle_r_in"
          />
          <InputText
            label="오른쪽 외(deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_rotation_angle_r_out")}
            error={errors}
            name="pat_hipjoint_rotation_angle_r_out"
          />
          <InputText
            label="왼쪽 내(deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_rotation_angle_l_in")}
            error={errors}
            name="pat_hipjoint_rotation_angle_l_in"
          />
          <InputText
            label="왼쪽 외(deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_hipjoint_rotation_angle_l_out")}
            error={errors}
            name="pat_hipjoint_rotation_angle_l_out"
          />

          <S.SurveyPatternTit>무릎관절 신전·굴곡</S.SurveyPatternTit>
          <InputText
            label="좌 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_kneejoint_flexion_l")}
            error={errors}
            name="pat_kneejoint_flexion_l"
          />
          <InputText
            label="우 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_kneejoint_flexion_r")}
            error={errors}
            name="pat_kneejoint_flexion_r"
          />

          <S.SurveyPatternTit>몸통 좌우 기울기</S.SurveyPatternTit>
          <InputText
            label="좌 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_bodylr_incline_l")}
            error={errors}
            name="pat_bodylr_incline_l"
          />
          <InputText
            label="우 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_bodylr_incline_r")}
            error={errors}
            name="pat_bodylr_incline_r"
          />
          <S.SurveyPatternTit>골반 좌우 이동 변위</S.SurveyPatternTit>
          <InputText
            label="좌 (cm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_pelvis_lr_movment_l")}
            error={errors}
            name="pat_pelvis_lr_movment_l"
          />
          <InputText
            label="우 (cm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_pelvis_lr_movment_r")}
            error={errors}
            name="pat_pelvis_lr_movment_r"
          />

          <S.SurveyPatternTit>수직 이동 변위</S.SurveyPatternTit>
          <InputText
            label="위 (cm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_vertical_movement_up")}
            error={errors}
            name="pat_vertical_movement_up"
          />

          <S.SurveyPatternTit>골반 회전 각도</S.SurveyPatternTit>
          <InputText
            label="좌 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_pelvic_rotation_angle_l")}
            error={errors}
            name="pat_pelvic_rotation_angle_l"
          />
          <InputText
            label="우 (deg)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_pelvic_rotation_angle_r")}
            error={errors}
            name="pat_pelvic_rotation_angle_r"
          />

          <S.SurveyPatternTit>보간·보폭</S.SurveyPatternTit>

          <InputText
            label="보폭-좌 (cm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_stride_l")}
            error={errors}
            name="pat_stride_l"
          />
          <InputText
            label="보폭-우 (cm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_stride_r")}
            error={errors}
            name="pat_stride_r"
          />
          <InputText
            label="보간(cm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_step_length")}
            error={errors}
            name="pat_step_length"
          />
        </S.ItemWrapper>
      </S.SurveyItems>

      <S.SurveyItems>
        <S.SurveySubTit>Gate View</S.SurveySubTit>
        <S.ItemWrapper>
          <InputText
            label="ENV(mm²)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_env")}
            error={errors}
            name="pat_env"
          />
          <InputText
            label="REC(mm²)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_rec")}
            error={errors}
            name="pat_rec"
          />
          <InputText
            label="RMS(mm²)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_rms")}
            error={errors}
            name="pat_rms"
          />
          <InputText
            label="TLC(mm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_tlc")}
            error={errors}
            name="pat_tlc"
          />
          <InputText
            label="Total Length(mm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_total_length")}
            error={errors}
            name="pat_total_length"
          />
          <InputText
            label="Sway velccity(mm/s)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_sway_velccity")}
            error={errors}
            name="pat_sway_velccity"
          />
          <InputText
            label="Length/ENV(mm)"
            layout="column"
            themeType="admin"
            size="xlg"
            width="100%"
            placeholder="입력..."
            register={register("pat_length_env")}
            error={errors}
            name="pat_length_env"
          />
        </S.ItemWrapper>
        <InputFile
          label="이미지 등록"
          id="image"
          img={img}
          imgPreview={imgPreview}
          onChangeImages={onChangeImages}
          onRemovePreviewImage={onRemovePreviewImage}
          onRemoveServerImage={onRemoveImage}
        />
      </S.SurveyItems>

      <Button
        type="submit"
        color="primary"
        layout="solid"
        width="100%"
        size="xlg"
        label="저장"
      />
    </S.SurveyEditForm>
  );
};
