import { useParams } from "react-router-dom";
import { getOneSurveyApi, getSurveyResultApi } from "../../apis/surveyApi";
import { ResultPage } from "../../components/templates/ResultPage";
import { useQuery } from "@tanstack/react-query";

export interface SurveyResultType {
  pat_age: number;
  /** 왼손악력 평균 */
  L_gripstrength_average: string;
  /** 오른손 악력 평균 */
  R_gripstrength_average: string;
  /** 이미지 이름 */
  filename: string;
  /** 8자 보행 */
  pat_eightwalk: number;
  /** 8자 보행 상대평가 기준 */
  pat_eightwalk_eval: number;
  /** ENV */
  pat_env: number;
  /** Length/ENV */
  pat_length_env: number;
  /** 이름 */
  pat_name: string;
  /** num (key)*/
  pat_num: number;
  /** REC */
  pat_rec: number;
  /** RMS */
  pat_rms: number;
  /** 앉았다 일어서기 */
  pat_sit_n_up: number;
  /** 앉았다 일어서기 상대평가 기준 */
  pat_sit_n_up_eval: number;
  /** Sway velccity  */
  pat_sway_velccity: number;
  /** TLC  */
  pat_tlc: number;
  /** Total Length */
  pat_total_length: number;
  /** 보행속도(cm/s) */
  pat_walking_speed: number;

  pat_neck_flexion_back: number;
  pat_neck_flexion_front: number;
  pat_bodybf_incline_back: number;
  pat_bodybf_incline_front: number;
  pat_hipjoint_flexion_r_front: number;
  pat_hipjoint_flexion_r_back: number;
  pat_hipjoint_flexion_l_front: number;
  pat_hipjoint_flexion_l_back: number;
  pat_hipjoint_rotation_angle_r_in: number;
  pat_hipjoint_rotation_angle_r_out: number;
  pat_hipjoint_rotation_angle_l_in: number;
  pat_hipjoint_rotation_angle_l_out: number;
  pat_kneejoint_flexion_l: number;
  pat_kneejoint_flexion_r: number;
  pat_bodylr_incline_r: number;
  pat_bodylr_incline_l: number;
  pat_pelvis_lr_movment_r: number;
  pat_pelvis_lr_movment_l: number;
  pat_vertical_movement_up: number;
  pat_pelvic_rotation_angle_r: number;
  pat_pelvic_rotation_angle_l: number;
  pat_step_length: number;
  pat_stride_r: number;
  pat_stride_l: number;

  pat_standing_highjump: number;
  pat_standing_highjump_eval: number;
}

const Result = () => {
  const params = useParams();
  const queryFn = () => {
    return getSurveyResultApi(params.id!);
  };
  const { data: surveyResult } = useQuery(["getSurveyResultApi"], queryFn);

  return (
    <>
      <ResultPage surveyResult={surveyResult} />
    </>
  );
};

export default Result;
