import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../Button";
import * as S from "./inputFile.style";

export const InputFile = ({
  id,
  onChangeImages,
  onRemovePreviewImage,
  onRemoveServerImage,
  imgPreview,
  img,
  label,
  disable,
}: any) => {
  return (
    <S.InputFile>
      <S.FileLabelBox>
        {label && label}
        {/* 프리뷰 이미지나, 서버에서 받아온 이미지 경로가 있다면 등록버튼 hide */}
        {imgPreview?.filename || img ? (
          ""
        ) : (
          <label htmlFor={id || "input-img"}>
            이미지 등록
            <input
              type="file"
              id={id || "input-img"}
              multiple
              hidden
              onChange={onChangeImages}
              disabled={disable}
            />
          </label>
        )}
      </S.FileLabelBox>

      <S.ImgPreviewList>
        {imgPreview && (
          <>
            <S.ImgPreviewItem
              key={imgPreview?.filename}
              style={{ display: "inline-block" }}
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_API_URL}/${imgPreview?.filename}`}
                alt={imgPreview}
                width={400}
                height={400}
              />
              <Button
                type="submit"
                color="inputDarkBg"
                layout="solid"
                width="100%"
                size="md"
                label="제거"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  onRemovePreviewImage(imgPreview, e)
                }
              />
            </S.ImgPreviewItem>
          </>
        )}
        {img && (
          <S.ImgPreviewItem key={id} style={{ display: "inline-block" }}>
            <img
              src={`${process.env.REACT_APP_PUBLIC_API_URL}/${img}`}
              alt={img}
              width={400}
              height={400}
            />
            <Button
              type="submit"
              color="inputDarkBg"
              layout="solid"
              width="100%"
              size="md"
              label="제거"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                onRemoveServerImage(img, e)
              }
            />
          </S.ImgPreviewItem>
        )}
      </S.ImgPreviewList>
    </S.InputFile>
  );
};
