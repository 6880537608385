import styled from "styled-components";

export const ResultPage = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  flex-direction: column;
`;

export const ResultSection = styled.div`
  display: flex;
  weight: 100%;
  background: ${(props) => props.theme.colors.white};
  flex-direction: column;
`;
