import React, { useState } from "react";
import { AdminLoginPage } from "../../components/templates/AdminLoginPage";

import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useMutation } from "@tanstack/react-query";
import { logInApi } from "../../apis/adminApi";

const Login = () => {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState({ user_id: "", password: "" });

  /** 로그인 제출 mutation */
  const mutation = useMutation(["logInApi"], logInApi, {
    onSuccess(res) {
      // react-auth-kit을 이용한 로그인 반환 토큰 쿠키에 저장
      signIn({
        token: res.accessToken,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { user_id: res.user_id },
      });

      setIsLoading(false);
      window.location.href = "/main";
    },
    onError(err: any) {
      const { response } = err;
      setErrorMessage(response.data.message);
      setIsLoading(false);
    },
  });

  /** 로그인 제출 핸들러 */
  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);

    e.preventDefault();
    mutation.mutate(userInfo);
  };

  return (
    <>
      <AdminLoginPage
        onSubmitForm={onSubmitForm}
        setUserInfo={setUserInfo}
        userInfo={userInfo}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default Login;
