import axios from "axios";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      typeof window !== "undefined" ? localStorage.getItem("_auth") : "",
  },
});

export default AxiosInstance;
