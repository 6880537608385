import { useState } from "react";
import * as S from "./resultSection.style";
import { SurveyAgree } from "../../molecules/SurveyAgree";
import ResultGrid from "../../molecules/ResultGrid";
import { SurveyResultType } from "../../../pages/Result";

interface ResultSectionProps {
  surveyResult: SurveyResultType;
}

const ResultSection = ({ surveyResult }: ResultSectionProps) => {
  return (
    <S.ResultSection>
      <ResultGrid surveyResult={surveyResult} />
    </S.ResultSection>
  );
};

export default ResultSection;
