import styled from "styled-components";

export const SurveyHeader = styled.header`
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  width: 100vw;
  height: 50px;
  top: 0;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.headerBorder};
  box-shadow: ${(props) => props.theme.shadow.header};
`;

export const SurveyTit = styled.div`
  font-size: 1.8rem;
`;
