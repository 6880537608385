import * as S from "../Input.style";
import { UseFormRegisterReturn } from "react-hook-form";

interface TextProps {
  width?: string;
  size: string;
  layout: string;
  themeType?: string;
  label?: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  onChange?: any;
  value?: any;
  type?: string;
  disable?: any;
  error?: any;
  name?: any;
}

export const InputText: React.FC<TextProps> = ({
  width,
  size,
  layout,
  themeType,
  label,
  placeholder,
  register,
  onChange,
  value,
  type = "text",
  disable,
  error,
  name,
}) => {
  return (
    <S.InputCommon
      layout={layout}
      themeType={themeType}
      size={size}
      width={width}
    >
      <label>
        {label && label}
        <input
          type={type}
          placeholder={placeholder}
          {...register}
          onChange={onChange}
          value={value}
          disabled={disable}
        />
        {error ? <p className="message">{error[name]?.message}</p> : ""}
      </label>
    </S.InputCommon>
  );
};
