/** 8자보행 상대평가 등급 */
export const eightWalkRelativeEvaluation = (
  sex: any,
  age: any,
  eightwalk: any
) => {
  // 남자
  if (sex === 1) {
    if (85 <= age) {
      if (21.0 <= eightwalk) {
        return 1;
      } else if (23.9 <= eightwalk) {
        return 2;
      } else if (eightwalk < 23.9) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (26.3 <= eightwalk) {
        return 1;
      } else if (30.8 <= eightwalk) {
        return 2;
      } else if (eightwalk < 30.8) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (24.0 <= eightwalk) {
        return 1;
      } else if (27.8 <= eightwalk) {
        return 2;
      } else if (eightwalk < 27.8) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (22.5 <= eightwalk) {
        return 1;
      } else if (25.6 <= eightwalk) {
        return 2;
      } else if (eightwalk < 25.6) {
        return 3;
      }
    } else if (age <= 69) {
      if (21.0 <= eightwalk) {
        return 1;
      } else if (23.9 <= eightwalk) {
        return 2;
      } else if (eightwalk < 23.9) {
        return 3;
      }
    }
  } else {
    //여자
    if (85 <= age) {
      if (30.8 <= eightwalk) {
        return 1;
      } else if (36.7 <= eightwalk) {
        return 2;
      } else if (eightwalk < 36.7) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (28.9 <= eightwalk) {
        return 1;
      } else if (34.1 <= eightwalk) {
        return 2;
      } else if (eightwalk < 34.1) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (26.7 <= eightwalk) {
        return 1;
      } else if (31.1 <= eightwalk) {
        return 2;
      } else if (eightwalk < 31.1) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (24.2 <= eightwalk) {
        return 1;
      } else if (27.9 <= eightwalk) {
        return 2;
      } else if (eightwalk < 27.9) {
        return 3;
      }
    } else if (age <= 69) {
      if (22.1 <= eightwalk) {
        return 1;
      } else if (24.9 <= eightwalk) {
        return 2;
      } else if (eightwalk < 24.9) {
        return 3;
      }
    }
  }
};

/** 앉았다 일어서기 상대평가 등급 */
export const sitNupRelativeEvaluation = (sex: any, age: any, sitNup: any) => {
  // 남자
  if (sex === 1) {
    if (85 <= age) {
      if (16 <= sitNup) {
        return 1;
      } else if (13 <= sitNup) {
        return 2;
      } else if (sitNup < 13) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (18 <= sitNup) {
        return 1;
      } else if (15 <= sitNup) {
        return 2;
      } else if (sitNup < 15) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (20 <= sitNup) {
        return 1;
      } else if (17 <= sitNup) {
        return 2;
      } else if (sitNup < 17) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (22 <= sitNup) {
        return 1;
      } else if (19 <= sitNup) {
        return 2;
      } else if (sitNup < 19) {
        return 3;
      }
    } else if (65 <= age && age <= 69) {
      if (25 <= sitNup) {
        return 1;
      } else if (21 <= sitNup) {
        return 2;
      } else if (sitNup < 13) {
        return 3;
      }
    }
    // 여기서부터 성인( 위는 노인 )
    else if (60 <= age && age <= 64) {
      if (16 <= sitNup) {
        return 1;
      } else if (10 <= sitNup) {
        return 2;
      } else if (sitNup < 10) {
        return 3;
      }
    } else if (50 <= age && age <= 59) {
      if (21 <= sitNup) {
        return 1;
      } else if (14 <= sitNup) {
        return 2;
      } else if (sitNup < 14) {
        return 3;
      }
    } else if (40 <= age && age <= 49) {
      if (26 <= sitNup) {
        return 1;
      } else if (17 <= sitNup) {
        return 2;
      } else if (sitNup < 17) {
        return 3;
      }
    } else if (30 <= age && age <= 39) {
      if (31 <= sitNup) {
        return 1;
      } else if (22 <= sitNup) {
        return 2;
      } else if (sitNup < 22) {
        return 3;
      }
    } else if (age <= 29) {
      if (37 <= sitNup) {
        return 1;
      } else if (26 <= sitNup) {
        return 2;
      } else if (sitNup < 26) {
        return 3;
      }
    }
  } else {
    //여자
    if (85 <= age) {
      if (14 <= sitNup) {
        return 1;
      } else if (11 <= sitNup) {
        return 2;
      } else if (sitNup < 11) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (15 <= sitNup) {
        return 1;
      } else if (13 <= sitNup) {
        return 2;
      } else if (sitNup < 13) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (18 <= sitNup) {
        return 1;
      } else if (15 <= sitNup) {
        return 2;
      } else if (sitNup < 15) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (20 <= sitNup) {
        return 1;
      } else if (17 <= sitNup) {
        return 2;
      } else if (sitNup < 17) {
        return 3;
      }
    } else if (65 <= age && age <= 69) {
      if (22 <= sitNup) {
        return 1;
      } else if (19 <= sitNup) {
        return 2;
      } else if (sitNup < 19) {
        return 3;
      }
    }

    // 여기서부터 성인( 위는 노인 )
    else if (60 <= age && age <= 64) {
      if (16 <= sitNup) {
        return 1;
      } else if (10 <= sitNup) {
        return 2;
      } else if (sitNup < 10) {
        return 3;
      }
    } else if (50 <= age && age <= 59) {
      if (21 <= sitNup) {
        return 1;
      } else if (14 <= sitNup) {
        return 2;
      } else if (sitNup < 14) {
        return 3;
      }
    } else if (40 <= age && age <= 49) {
      if (26 <= sitNup) {
        return 1;
      } else if (17 <= sitNup) {
        return 2;
      } else if (sitNup < 17) {
        return 3;
      }
    } else if (30 <= age && age <= 39) {
      if (31 <= sitNup) {
        return 1;
      } else if (22 <= sitNup) {
        return 2;
      } else if (sitNup < 22) {
        return 3;
      }
    } else if (age <= 29) {
      if (37 <= sitNup) {
        return 1;
      } else if (26 <= sitNup) {
        return 2;
      } else if (sitNup < 26) {
        return 3;
      }
    }
  }
};

/** 왼쪽 악력 상대평가 등급 */
export const gripstrengthRelativeEvaluationL = (
  sex: number,
  age: number,
  LgripAverage: number
) => {
  if (sex === 1) {
    if (85 <= age) {
      if (45.4 <= LgripAverage) {
        return 1;
      } else if (39.6 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 39.6) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (47.5 <= LgripAverage) {
        return 1;
      } else if (42.0 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 42.0) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (50.6 <= LgripAverage) {
        return 1;
      } else if (45.2 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 45.2) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (53.6 <= LgripAverage) {
        return 1;
      } else if (48.3 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 48.3) {
        return 3;
      }
    } else if (age <= 69) {
      if (55.3 <= LgripAverage) {
        return 1;
      } else if (50.1 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 50.1) {
        return 3;
      }
    }
  } else {
    //여자
    if (85 <= age) {
      if (32.7 <= LgripAverage) {
        return 1;
      } else if (27.9 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 27.9) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (34.4 <= LgripAverage) {
        return 1;
      } else if (29.9 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 29.9) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (36.2 <= LgripAverage) {
        return 1;
      } else if (31.8 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 31.8) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (38.4 <= LgripAverage) {
        return 1;
      } else if (34.1 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 34.1) {
        return 3;
      }
    } else if (age <= 69) {
      if (40.6 <= LgripAverage) {
        return 1;
      } else if (36.4 <= LgripAverage) {
        return 2;
      } else if (LgripAverage < 36.4) {
        return 3;
      }
    }
  }
  return null;
};
/** 오른쪽 악력 상대평가 등급 */
export const gripstrengthRelativeEvaluationR = (
  sex: number,
  age: number,
  RgripAverage: number
) => {
  if (sex === 1) {
    if (85 <= age) {
      if (45.4 <= RgripAverage) {
        return 1;
      } else if (39.6 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 39.6) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (47.5 <= RgripAverage) {
        return 1;
      } else if (42.0 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 42.0) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (50.6 <= RgripAverage) {
        return 1;
      } else if (45.2 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 45.2) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (53.6 <= RgripAverage) {
        return 1;
      } else if (48.3 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 48.3) {
        return 3;
      }
    } else if (age <= 69) {
      if (55.3 <= RgripAverage) {
        return 1;
      } else if (50.1 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 50.1) {
        return 3;
      }
    }
  } else {
    //여자
    if (85 <= age) {
      if (32.7 <= RgripAverage) {
        return 1;
      } else if (27.9 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 27.9) {
        return 3;
      }
    } else if (80 <= age && age <= 84) {
      if (34.4 <= RgripAverage) {
        return 1;
      } else if (29.9 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 29.9) {
        return 3;
      }
    } else if (75 <= age && age <= 79) {
      if (36.2 <= RgripAverage) {
        return 1;
      } else if (31.8 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 31.8) {
        return 3;
      }
    } else if (70 <= age && age <= 74) {
      if (38.4 <= RgripAverage) {
        return 1;
      } else if (34.1 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 34.1) {
        return 3;
      }
    } else if (age <= 69) {
      if (40.6 <= RgripAverage) {
        return 1;
      } else if (36.4 <= RgripAverage) {
        return 2;
      } else if (RgripAverage < 36.4) {
        return 3;
      }
    }
  }
  return null;
};

export const standingHighjumpEvaluation = (
  sex: number,
  age: number,
  data: number
) => {
  if (sex === 1) {
    if (60 <= age && age <= 64) {
      if (data < 0.443) {
        return 3;
      } else if (data < 0.474) {
        return 2;
      } else if (0.474 <= data) {
        return 1;
      }
    } else if (55 <= age && age <= 59) {
      if (data < 0.475) {
        return 3;
      } else if (data < 0.508) {
        return 2;
      } else if (0.508 <= data) {
        return 1;
      }
    } else if (50 <= age && age <= 54) {
      if (data < 0.486) {
        return 3;
      } else if (data < 0.527) {
        return 2;
      } else if (0.527 <= data) {
        return 1;
      }
    } else if (45 <= age && age <= 49) {
      if (data < 0.497) {
        return 3;
      } else if (data < 0.524) {
        return 2;
      } else if (0.524 <= data) {
        return 1;
      }
    } else if (40 <= age && age <= 44) {
      if (data < 0.521) {
        return 3;
      } else if (data < 0.547) {
        return 2;
      } else if (0.547 <= data) {
        return 1;
      }
    } else if (35 <= age && age <= 39) {
      if (data < 0.551) {
        return 3;
      } else if (data < 0.581) {
        return 2;
      } else if (0.581 <= data) {
        return 1;
      }
    } else if (30 <= age && age <= 34) {
      if (data < 0.548) {
        return 3;
      } else if (data < 0.583) {
        return 2;
      } else if (0.583 <= data) {
        return 1;
      }
    } else if (25 <= age && age <= 29) {
      if (data < 0.559) {
        return 3;
      } else if (data < 0.591) {
        return 2;
      } else if (0.591 <= data) {
        return 1;
      }
    } else if (age <= 24) {
      if (data < 0.568) {
        return 3;
      } else if (data < 0.605) {
        return 2;
      } else if (0.605 <= data) {
        return 1;
      }
    }
    return null;
  } else {
    //여자
    if (60 <= age && age <= 64) {
      if (data < 0.366) {
        return 3;
      } else if (data < 0.393) {
        return 2;
      } else if (0.393 <= data) {
        return 1;
      }
    } else if (55 <= age && age <= 59) {
      if (data < 0.374) {
        return 3;
      } else if (data < 0.402) {
        return 2;
      } else if (0.402 <= data) {
        return 1;
      }
    } else if (50 <= age && age <= 54) {
      if (data < 0.381) {
        return 3;
      } else if (data < 0.407) {
        return 2;
      } else if (0.407 <= data) {
        return 1;
      }
    } else if (45 <= age && age <= 49) {
      if (data < 0.404) {
        return 3;
      } else if (data < 0.431) {
        return 2;
      } else if (0.431 <= data) {
        return 1;
      }
    } else if (40 <= age && age <= 44) {
      if (data < 0.417) {
        return 3;
      } else if (data < 0.442) {
        return 2;
      } else if (0.442 <= data) {
        return 1;
      }
    } else if (35 <= age && age <= 39) {
      if (data < 0.425) {
        return 3;
      } else if (data < 0.453) {
        return 2;
      } else if (0.453 <= data) {
        return 1;
      }
    } else if (30 <= age && age <= 34) {
      if (data < 0.437) {
        return 3;
      } else if (data < 0.464) {
        return 2;
      } else if (0.464 <= data) {
        return 1;
      }
    } else if (25 <= age && age <= 29) {
      if (data < 0.442) {
        return 3;
      } else if (data < 0.466) {
        return 2;
      } else if (0.466 <= data) {
        return 1;
      }
    } else if (age <= 24) {
      if (data < 0.447) {
        return 3;
      } else if (data < 0.479) {
        return 2;
      } else if (0.479 <= data) {
        return 1;
      }
    }
  }
  return null;
};
