import styled, { css } from "styled-components";

interface InputProps {
  width?: string;
  size?: string;
  layout?: string;
  themeType?: string;
}

export const InputCommon = styled.div<InputProps>`
  display: flex;

  label {
    display: flex;
    width: ${(props) => (props.width ? props.width : "initial")};
    color: ${(props) => props.theme.colors.adminLabelTxt};
    font-size: 1.4rem;
    white-space: nowrap;
    align-items: center;
    gap: 5px 10px;

    .displayValue {
      font-size: 1.4rem;
      cursor: pointer;
    }
  }

  input,
  select,
  textarea {
    width: ${(props) => (props.width ? props.width : "initial")};
    height: ${(props) =>
      props.size === "sm"
        ? "24px"
        : props.size === "md"
        ? "30px"
        : props.size === "lg"
        ? "40px"
        : props.size === "xlg"
        ? "48px"
        : "unset"};
    padding: ${(props) =>
      props.size === "sm" || props.size === "md"
        ? "0 6px 2px"
        : props.size === "lg"
        ? "0 10px"
        : props.size === "xlg"
        ? "0 15px"
        : "0"};
    font-size: ${(props) =>
      props.size === "lg"
        ? "1.5rem"
        : props.size === "xlg"
        ? "1.6rem"
        : "1.3rem"};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.inputBorder};
  }

  textarea {
    height: 120px;
    padding: 10px;
    font-size: 1.3rem;
  }

  input[type="radio"] {
    width: 20px;
    height: 20px;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }

  ${(props) =>
    props.layout === "column" &&
    css`
      flex-direction: column;
      gap: 5px 10px;

      label {
        flex-direction: column;
        align-items: flex-start;
      }
    `}

  ${(props) =>
    props.layout === "row" &&
    css`
      label {
        color: ${(props) => props.theme.colors.adminLabelTxt};

        input {
          border: 1px solid ${(props) => props.theme.colors.adminInputBorder};
          border-radius: 3px;
        }
      }
    `}
`;

export const InputCheckTit = styled.div`
  color: ${(props) => props.theme.colors.adminLabelTxt};
  font-size: 1.4rem;
  white-space: nowrap;
`;

export const InputCheckbox = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  label {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`;
