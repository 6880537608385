import React, { useCallback, useMemo, useState } from "react";
import { Button } from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import { addSurveyApi, getSurveyListApi } from "../../../apis/surveyApi";
import { SurveyDeleteModal } from "../AdminModal/SurveyDeleteModal";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import * as S from "./targetList.style";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { useSignOut } from "react-auth-kit";

const MainGrid = ({ search }: any) => {
  const navigate = useNavigate();
  const [storeModal, setStoreModal] = useState(false);
  const [store, setStore] = useState();
  const signOut = useSignOut();

  const { data: postItem, isLoading } = useQuery(
    ["getSurveyListApi", search.name, search.id, search.birth],
    getSurveyListApi,
    {
      retry: 1,
      onError(err: any) {
        const { response } = err;
        if (response.status === 401) {
          alert("로그인 유효 기간이 만료되었습니다. 다시 로그인해주세요");
          signOut();
        }
      },
    }
  );

  /** 컬럼 값 */
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "pat_name",
      headerName: "이름",
      maxWidth: 400,
      autoHeight: true,
      wrapText: true,
    },
    { field: "pat_id", headerName: "아이디" },
    { field: "pat_phone", headerName: "전화번호", maxWidth: 200 },
    { field: "regdate", headerName: "등록일", maxWidth: 200 },
    {
      field: "action",
      headerName: "수정",
      maxWidth: 250,
      cellRendererFramework: (params: any) => (
        <S.MainGridBtnCell>
          <Button
            type="button"
            color="primary"
            layout="solid"
            size="md"
            width="60px"
            label="수정"
            onClick={() => navigate(`/survey/${params.data.pat_num}`)}
          />
          <Button
            type="button"
            color="func"
            layout="solid"
            size="md"
            width="60px"
            label="삭제"
            onClick={() => openStoreModal(params)}
          />
          {params.data.old || params.data.young ? (
            <Button
              type="button"
              color="func"
              layout="solid"
              size="md"
              width="60px"
              label="결과보기"
              onClick={() => navigate(`/survey/result/${params.data.pat_num}`)}
            />
          ) : (
            ""
          )}
        </S.MainGridBtnCell>
      ),
    },
  ]);

  const openStoreModal = (data: any) => {
    setStore(data);
    setStoreModal(!storeModal);
  };

  /** 데이터 페칭 */
  // const onGridReady = useCallback((params: any) => {
  //   fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/survey`)
  //     .then((resp) => resp.json())
  //     .then((data) => {
  //       const dataSource = {
  //         rowCount: undefined,
  //         getRows: (params: any) => {
  //           console.log(
  //             "asking for " + params.startRow + " to " + params.endRow
  //           );
  //           // At this point in your code, you would call the server.
  //           // To make the demo look real, wait for 500ms before returning
  //           setTimeout(function () {
  //             // take a slice of the total rows
  //             const rowsThisPage = data.slice(params.startRow, params.endRow);
  //             // if on or after the last page, work out the last row.
  //             let lastRow = -1;
  //             if (data.length <= params.endRow) {
  //               lastRow = data.length;
  //             }
  //             // call the success callback
  //             params.successCallback(rowsThisPage, lastRow);
  //           }, 500);
  //         },
  //       };
  //       params.api.setDatasource(dataSource);
  //     });
  // }, []);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
    };
  }, []);

  return (
    <>
      <S.MainGridBox>
        <div className="ag-theme-alpine">
          <AgGridReact
            rowData={postItem}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
            rowBuffer={0}
            defaultColDef={defaultColDef}
            // rowModelType={"infinite"}
            // cacheBlockSize={200}
            // cacheOverflowSize={2}
            // maxConcurrentDatasourceRequests={1}
            // infiniteInitialRowCount={1000}
            // maxBlocksInCache={10}
            // onGridReady={onGridReady}
          />
        </div>
      </S.MainGridBox>
      {storeModal && (
        <SurveyDeleteModal
          title={"삭제하시겠습니까??"}
          onClose={openStoreModal}
          store={store}
        />
      )}
    </>
  );
};

export default MainGrid;
