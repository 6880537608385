import { useState } from "react";
import MainGrid from "../../molecules/MainGrid";
import MainSearch from "../../molecules/MainSearch";
import * as S from "./mainPage.style";

import { MainHeader } from "../../molecules/MainHeader";
import { SearchState } from "../../../pages/Main";

interface MainPageProps {
  search: SearchState;
  setSearch: React.Dispatch<React.SetStateAction<SearchState>>;
  signOutHandler: () => void;
}
export const MainPage = ({
  search,
  setSearch,
  signOutHandler,
}: MainPageProps) => {
  return (
    <S.MainPage>
      <MainHeader signOutHandler={signOutHandler} />
      <S.MainContent>
        <MainSearch setSearch={setSearch} search={search} />
        <MainGrid search={search} />
      </S.MainContent>
    </S.MainPage>
  );
};
