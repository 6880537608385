import { useNavigate } from "react-router-dom";
import { Button } from "../../atoms/Button";
import * as S from "./mainHeader.style";

interface MainHeaderProps {
  signOutHandler: () => void;
}

export const MainHeader = ({ signOutHandler }: MainHeaderProps) => {
  const navigate = useNavigate();
  return (
    <S.MainHeader>
      <Button
        type="button"
        color="func"
        layout="solid"
        width="80px"
        size="md"
        label="로그아웃"
        onClick={signOutHandler}
      />
      <S.HeadTit>대상자 목록</S.HeadTit>
      <Button
        type="button"
        color="primary"
        layout="solid"
        width="80px"
        size="md"
        label="신규등록"
        onClick={() => navigate("/survey")}
      />
    </S.MainHeader>
  );
};
