import styled from "styled-components";

export const SurveyForm = styled.form`
  display: flex;
  padding: 16px;
  background: ${(props) => props.theme.colors.adminBg};
  flex-direction: column;
  gap: 16px;
`;

export const SurveyItems = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  background: ${(props) => props.theme.colors.white};
  gap: 20px;
`;

export const SurveySubTit = styled.div`
  font-size: 2rem;
  font-weight: 500;
`;

export const SurveyPatternWrapper = styled.div`
  grid-column: 1fr 1fr;
  font-size: 2rem;
  font-weight: 500;
  display: flex;
`;

export const SurveyPatternTit = styled.div`
  font-size: 1.7rem;
  color: ${(props) => props.theme.colors.dark};
  font-weight: 500;
  grid-column: 1/-1;
  border-top: 1px solid rgb(221, 221, 221);
  padding-top: 20px;
`;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px 20px;
  margin-bottom: 15px;
`;

export const SurveySubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
