import styled from "styled-components";

export const SurveySection = styled.div`
  display: flex;
  weight: 100%;
  background: ${(props) => props.theme.colors.white};
  flex-direction: column;
`;

export const SurveyDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.headerBorder};
`;
