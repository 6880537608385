import { useParams } from "react-router-dom";
import { SurveyHeader } from "../../molecules/SurveyHeader";
import SurveyEditSection from "../../organisms/SurveyEditSection";
import SurveySection from "../../organisms/SurveySection";

import * as S from "./resultPage.style";
import ResultSection from "../../organisms/ResultSection";
import { SurveyResultType } from "../../../pages/Result";

interface ResultPageProps {
  surveyResult: SurveyResultType;
}

export const ResultPage = ({ surveyResult }: ResultPageProps) => {
  return (
    <S.ResultPage>
      <SurveyHeader title="결과 페이지" />
      <ResultSection surveyResult={surveyResult} />
    </S.ResultPage>
  );
};
