import { useParams } from "react-router-dom";
import { SurveyHeader } from "../../molecules/SurveyHeader";
import SurveyEditSection from "../../organisms/SurveyEditSection";
import SurveySection from "../../organisms/SurveySection";

import * as S from "./surveyPage.style";

export const SurveyPage = () => {
  const param = useParams();

  return (
    <S.SurveyPage>
      <SurveyHeader title="설문 페이지" />
      {/* param.id 가있다면 설문 수정폼으로 */}
      {param.id ? <SurveyEditSection /> : <SurveySection />}
    </S.SurveyPage>
  );
};
