import axiosInstance from "./index";

export function addSurveyApi(data: Object) {
  return axiosInstance.post("/survey", data).then((response) => response.data);
}

export function getSurveysListApi(lastId?: number) {
  return axiosInstance
    .get(`/survey?lastId=${lastId || 0}`)
    .then((response) => response.data);
}

export function getSurveyListApi({ queryKey }: any) {
  return axiosInstance
    .get(`/survey?name=${queryKey[1]}&id=${queryKey[2]}&birth=${queryKey[3]}`)
    .then((response) => response.data);
}

export function getOneSurveyApi(id: string) {
  return axiosInstance
    .get(`/survey/${id}`)
    .then((response) => response.data[0]);
}

export function editSurveyApi(surveyData: any) {
  return axiosInstance
    .put(`/survey/${surveyData.id}`, surveyData.data)
    .then((response) => response.data);
}

/** DELETE 설문 삭제 */
export function deleteSurveyApi(id: string) {
  return axiosInstance
    .delete(`/survey/${id}`)
    .then((response) => response.data);
}

/** POST 설문 이미지 업로드 */
export function uploadImagesApi(data: FormData) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axiosInstance
    .post("/survey/images", data, config)
    .then((response) => response.data);
}

/** DELETE DB에 올라가지 않은 프리뷰 이미지 삭제 */
export function deletePreviewImagesApi(data: string) {
  return axiosInstance
    .delete(`/survey/images/preview/${data}`)
    .then((response) => response.data);
}

/** DELETE DB 이미지 삭제 */
export function deleteImageApi(data: string) {
  return axiosInstance
    .delete(`/survey/images/${data}`)
    .then((response) => response.data);
}

export function getSurveyResultApi(id: string) {
  return axiosInstance
    .get(`/survey/result/${id}`)
    .then((response) => response.data[0]);
}
