import { useState } from "react";
import { SurveyAgree } from "../../molecules/SurveyAgree";
import { SurveyForm } from "../../molecules/SurveyForm";
import * as S from "./surveySection.style";

const SurveySection = () => {
  const [disable, setDisable] = useState(true);

  return (
    <S.SurveySection>
      <SurveyAgree setDisable={setDisable} />
      <SurveyForm disable={disable} />
    </S.SurveySection>
  );
};

export default SurveySection;
