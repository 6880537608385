import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MainGridBox = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 144px);

  //메인 그리드 style
  .ag-theme-alpine {
    width: 100%;
    height: 100%;

    //메인 그리드 외곽 border
    .ag-root-wrapper {
      border: none;
    }

    //메인 그리드 헤더 style
    .ag-header {
      background: ${(props) => props.theme.colors.func};
      border-radius: 4px;

      .ag-cell-label-container {
        font-size: 1.6rem;
        color: ${(props) => props.theme.colors.white};
      }
    }

    //메인 그리드 로우 style
    .ag-row {
      border: none;

      &.ag-row-odd {
        background: ${(props) => props.theme.colors.gridOddBg};
      }

      .ag-cell {
        display: flex;
        font-size: 1.5rem;
        align-items: center;
      }
    }
  }
`;

export const MainGridBtnCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
