import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AdminModal } from "..";
import { Button } from "../../../atoms/Button";
import { deleteSurveyApi } from "../../../../apis/surveyApi";

export const SurveyDeleteModal = ({ onClose, store, title }: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation(["deleteSurveyApi"], deleteSurveyApi, {
    onSuccess: () => {
      // ✋ 삭제가 성공하면 리스트를 다시 get
      queryClient.invalidateQueries(["getSurveyListApi"]);
      onClose();
    },
  });

  const goDelete = () => {
    mutation.mutate(store.data.pat_num);
  };

  return (
    <AdminModal>
      <p style={{ fontSize: "14px" }}>{title}</p>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          type="button"
          layout="solid"
          width="60px"
          height={36}
          color="func"
          label="닫기"
          onClick={onClose}
        />
        <Button
          type="button"
          width="60px"
          height={36}
          color="primary"
          layout="solid"
          label="삭제"
          onClick={goDelete}
        />
      </div>
    </AdminModal>
  );
};
