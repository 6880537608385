import styled from "styled-components";

export const ResultGridBox = styled.div`
  display: flex;
  padding: 16px;
  background: ${(props) => props.theme.colors.adminBg};
  flex-direction: column;
  gap: 16px;
`;

export const ResultGridTxt = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.8rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  background: ${(props) => props.theme.colors.adminBg};
  flex-direction: column;
`;

export const ResultItems = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  background: ${(props) => props.theme.colors.white};
  gap: 20px;
`;

export const ResultGridTit = styled.span`
  display: flex;
  width: 100%;
  font-size: 1.8rem;
`;

export const ImgPreviewItem = styled.li`
  overflow: hidden;
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.adminInputBorder};
`;

/** 임시 스타일 */
export const ResultGridSub = styled.div`
  font-size: 1.4rem;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.8rem;
`;
