import * as S from "../Input.style";
import { UseFormRegisterReturn } from "react-hook-form";

interface TextProps {
  width?: string;
  size: string;
  themeType?: string;
  layout: string;
  title?: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  disable?: any;
  value?: any;
  onChange?: any;
  error?: any;
  name?: any;
}

export const InputRadio: React.FC<TextProps> = ({
  width,
  size,
  layout,
  themeType,
  title,
  register,
  placeholder,
  disable,
  value,
  onChange,
  error,
  name,
}) => {
  return (
    <S.InputCommon
      layout={layout}
      size={size}
      width={width}
      themeType={themeType}
    >
      <S.InputCheckTit>{title && title}</S.InputCheckTit>
      <S.InputCheckbox>
        <label>
          <input
            type="radio"
            value="1"
            {...register}
            disabled={disable}
            onChange={onChange}
          />
          남
        </label>
        <label>
          <input
            type="radio"
            value="2"
            {...register}
            disabled={disable}
            onChange={onChange}
          />
          여
        </label>
      </S.InputCheckbox>
      {error ? <p className="message">{error[name]?.message}</p> : ""}
    </S.InputCommon>
  );
};
