import { SurveyPage } from "../../components/templates/SurveyPage";

const SurveyEdit = () => {
  return (
    <>
      <SurveyPage />
    </>
  );
};

export default SurveyEdit;
