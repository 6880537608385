import { AdminLoginBox } from "../../molecules/AdminLoginBox";
import * as S from "./adminLoginPage.style";

interface AdminLoginBoxProps {
  onSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  setUserInfo: React.Dispatch<
    React.SetStateAction<{ user_id: string; password: string }>
  >;
  userInfo: { user_id: string; password: string };
  isLoading: boolean;
  errorMessage: string;
}

export const AdminLoginPage = ({
  onSubmitForm,
  setUserInfo,
  userInfo,
  isLoading,
  errorMessage,
}: AdminLoginBoxProps) => {
  return (
    <S.LoginPage>
      <AdminLoginBox
        onSubmitForm={onSubmitForm}
        setUserInfo={setUserInfo}
        userInfo={userInfo}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
    </S.LoginPage>
  );
};
