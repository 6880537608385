import styled from "styled-components";

export const SurveyAgreeBox = styled.div`
  display: flex;
  padding: 40px 32px;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const SurveyAgreeTxt = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.8rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  background: ${(props) => props.theme.colors.adminBg};
  flex-direction: column;
`;

export const SurveyAgreeTit = styled.span`
  display: flex;
  width: 100%;
  font-size: 1.8rem;
`;
